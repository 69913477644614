<template>
	<div>
		<!-- 科目选择 -->
		<van-popup v-model="subjectShow" position="bottom" round>
			<div class="subject-view">
				<div class="row cb subject-header">
					<div class="current" v-if="timeOut">已选：{{ majorInfo.major_name || '全部' }}</div>
					<div class="current" v-else>已选：{{ saveData.major_name || '请选择' }}</div>
					<div class="cancel" @click="subjectShow = false">取消</div>
				</div>
				<div class="search-box">
					<div class="row search-view">
						<van-image class="search-img" :src="require('@/assets/search.png')" fit="cover" />
						<input type="text" v-model="keywords" placeholder="请输入要搜索的关键词" enterkeyhint="search" @keyup.enter="enterSearch($event)" />
					</div>
				</div>
				<div class="row scroll-column">
					<template v-if="loading">
						<div class="row cc " style="width: 100%;">
							<van-loading />
							<span class="loading-text">加载中……</span>
						</div>
					</template>
					<template v-else-if="dataList.length == 0">
						<div class="row cc" style="width: 100%;"><van-empty description="暂无数据" image="search" /></div>
					</template>
					<template v-else>
						<div class="category">
							<div class="row category-name" :class="{ active: saveData.major_name == '' }" @click="clearCate()" v-if="timeOut">全部</div>
							<template v-for="(item, index) in dataList">
								<div class="row group-title">{{ item.name }}</div>
								<template v-for="(value, key) in item.children">
									<div class="row category-name" :class="{ active: currentIndex[0] === index && currentIndex[1] === key }" @click="cateChange(index, key)">
										{{ value.name }}
									</div>
								</template>
							</template>
						</div>
						<div class="children-list">
							<template v-for="(v, k) in childrenList">
								<div class="row children-name" :class="{ active: currentIndex[2] === k }" @click="confirmSub(k)">{{ v.name }}</div>
							</template>
						</div>
					</template>
				</div>
			</div>
		</van-popup>
	</div>
</template>

<script>
import { mapState } from 'vuex';
export default {
	props: {
		timeOut: {
			type: Boolean,
			default: () => true
		}
	},
	data() {
		return {
			keywords: '',
			subjectShow: false,
			dataList: [],
			currentIndex: [],
			loading: false,
			saveData: { major_name: '' }
		};
	},
	computed: {
		childrenList() {
			if (this.dataList.length !== 0 && typeof this.currentIndex[0] == 'number' && typeof this.currentIndex[1] == 'number') {
				return this.dataList[this.currentIndex[0]].children[this.currentIndex[1]].children;
			} else {
				return [];
			}
		},
		...mapState(['majorInfo'])
	},
	created() {
		this.currentIndex = this.timeOut ? ['', '', ''] : [0, 0, ''];
		this.wechatH5Share();
		this.getData();
	},
	beforeDestroy() {},
	methods: {
		async getData() {
			this.loading = true;
			let res = await this.$service.post('category/majorIndex', { name: this.keywords });
			this.dataList = res.data;
			this.loading = false;
		},
		enterSearch() {
			console.log(this.keywords);
			this.getData();
		},
		cateChange(index, key) {
			this.currentIndex = [index, key, ''];
		},
		clearCate() {
			this.currentIndex = ['', '', ''];
			this.$toast({
				type: 'success',
				message: '分类选择成功'
			});
			this.$emit('confim', {});
			this.close();
		},
		confirmSub(k) {
			this.$set(this.currentIndex, 2, k);
			this.saveData = {
				major_id: this.childrenList[k].id,
				major_name: this.childrenList[k].name,
				major: `${this.dataList[this.currentIndex[0]].name}/${this.dataList[this.currentIndex[0]].children[this.currentIndex[1]].name}/${this.childrenList[k].name}`
			};
			if (this.timeOut) {
				this.$toast({
					type: 'success',
					message: '分类选择成功'
				});
				this.$service
					.post('category/setSubject', { subject_id: this.childrenList[k].id })
					.then(() => {
						this.$emit('confim', this.saveData);
					})
					.finally(() => {
						this.close();
					});
			} else {
				this.close();
				this.$emit('confim', this.saveData);
			}
		},
		open() {
			this.subjectShow = true;
		},
		close() {
			this.subjectShow = false;
		}
	}
};
</script>

<style scoped lang="less">
// 科目选择
.subject-view .search-view {
	align-items: center;
	height: 1.066666rem;
	border-radius: 0.533333rem;
	border: 1px solid #333333;
	padding: 0 0.373333rem;
	box-sizing: border-box;
	.search-img {
		width: 0.4rem;
		height: 0.4rem;
	}
	input {
		flex: 1;
		height: 100%;
		border: none;
		margin: 0;
		padding: 0;
		font-size: 0.426666rem;
		margin-left: 0.266666rem;
		background-color: transparent;
	}
}
.subject-view {
	.subject-header {
		height: 1.6rem;
		border-bottom: 1px solid #ededed;
		padding: 0 0.533333rem;
		box-sizing: border-box;
		.current {
			color: #333333;
			font-size: 0.426666rem;
			font-weight: bold;
		}
		.cancel {
			color: @green;
			font-size: 0.426666rem;
		}
	}
	.search-box {
		padding: 0.533333rem;
		box-sizing: border-box;
	}
	.scroll-column {
		.loading-text {
			margin-left: 0.16rem;
			color: #c9c9c9;
			font-size: 0.373333rem;
		}
		height: 343px;
		.category {
			overflow-y: auto;
			width: 120px;
			height: 100%;
			background: #f8f8f8;
			color: #666666;
			font-size: 0.373333rem;
			.group-title {
				align-items: center;
				min-height: 0.906666rem;
				padding-left: 0.12rem;
				color: #999999;
				font-size: 0.32rem;
			}
			.category-name {
				align-items: center;
				min-height: 0.906666rem;
				padding: 0.12rem 0 0.12rem 0.64rem;
				box-sizing: border-box;
				position: relative;
				&::after {
					content: '';
					width: 0.106666rem;
					height: 100%;
					background: #f8f8f8;
					position: absolute;
					left: 0;
					top: 0;
				}
			}
			.active.category-name {
				background-color: #ffffff;
				font-weight: bold;
				&::after {
					background: @green;
				}
			}
		}
		.children-list {
			overflow-y: auto;
			flex: 1;
			color: #999999;
			font-size: 0.373333rem;
			padding: 0 0.426666rem;
			box-sizing: border-box;
			.children-name {
				align-items: center;
				padding: 0.16rem 0.426666rem;
				box-sizing: border-box;
				min-height: 1.066666rem;
				border-bottom: 1px solid #ededed;
				&:last-of-type {
					border: none;
				}
			}
			.active {
				color: #333333;
				font-weight: bold;
			}
		}
	}
}
</style>

<template>
	<div class="container">
		<div class="form">
			<van-form validate-first @failed="onFailed">
				<div class="col header-form group-item">
					<!-- <div class="row cb title-input">
						<input v-model="form.title" type="text" placeholder="用简要文字描述问题" maxlength="20" class="van-field__control" />
						<div class="count-text">{{ form.title.length }}/20</div>
					</div> -->
					<div class="textarea"><textarea rows="6" v-model="form.content" placeholder="请简单描述您的问题……" class="van-field__control"></textarea></div>
					<van-uploader v-model="fileList" :after-read="afterRead" @delete="delImg" multiple :max-count="9" />
				</div>
				<van-field label="问题分类" class="group-margin" :border="false">
					<template #input>
						<div class="van-cell__value van-field__value" @click="openSubjectSelect()">
							<div class="van-field__body">
								<input type="text" readonly="readonly" placeholder="问题分类选择" :value="displayForm.major" class="van-field__control" />
								<van-image class="arrow" :src="require('@/assets/arrow-right-gray.png')" fit="cover" />
							</div>
						</div>
					</template>
				</van-field>
				<!-- <div class="group-item">
					<div class="van-cell__title">问题标签</div>
					<div class="row tags-view">
						<div class="row cc tag" v-for="(item, index) in displayForm.tags" :key="index" @click="removeTag(index)">
							<span>{{ item }}</span>
							<van-icon name="cross" size="10" />
						</div>
						<div class="row cc tag add-tag" @click="showTagInput = !showTagInput">
							<van-icon name="plus" size="10" />
							<span>添加标签</span>
						</div>
					</div>
					<div class="row cc tag-input" v-show="showTagInput">
						<input v-model="tagInputText" type="text" placeholder="回车确认添加标签" class="van-field__control" enterkeyhint="done" @keyup.enter="enterTag($event)" />
					</div>
					<div class="row count-box">
						<div class="count-text" style="margin-left: auto;">{{ displayForm.tags.length }}/5</div>
					</div>
				</div> -->
				<!-- <div class="group-item">
					<div class="van-cell__title">
						<span @click="rmbToast = true">问题悬赏</span>
						<van-icon name="info-o" size="10" style="margin-left: 0.16rem;" @click="rmbToast = true" />
					</div>
					<van-radio-group v-model="form.type">
						<van-radio name="1" checked-color="#03D3BC">积分悬赏</van-radio>
						<div v-show="form.type == 1">
							<div class="row tags-view">
								<div class="row cc tag" :class="{ active: form.point == item }" v-for="(item, index) in integralList" :key="index" @click="form.point = item">
									<span>{{ item }}</span>
								</div>
							</div>
							<div class="row cc tag-input">
								<input
									v-model="pointInputText"
									type="number"
									placeholder="自定义悬赏积分 100-1000"
									class="van-field__control"
									enterkeyhint="done"
									@keyup.enter="enterPoint($event)"
								/>
							</div>
							<div class="row mine-integral">
								<span>我的积分：</span>
								<span>{{ userInfo.point || 0 }}</span>
								<van-popover v-model="showPointTips" trigger="click" placement="top" theme="dark">
									<div class="row cc tips-text">回答他人提出的问题，或参与互动获取积分</div>
									<template #reference>
										<div class="row tips">
											<div>积分不足如何获取积分？</div>
											<van-icon name="question-o" size="10" />
										</div>
									</template>
								</van-popover>
							</div>
						</div>

						<van-radio name="2" checked-color="#03D3BC">现金悬赏</van-radio>
						<div v-show="form.type == 2">
							<div class="row tags-view">
								<div class="row cc tag" :class="{ active: form.amount == item }" v-for="(item, index) in rmbList" :key="index" @click="form.amount = item">
									<span>￥{{ item }}</span>
								</div>
							</div>
							<div class="row cc tag-input">
								<input
									v-model="amountInputText"
									type="number"
									step="0.01"
									placeholder="自定义悬赏金额 1-10"
									class="van-field__control"
									enterkeyhint="done"
									@keyup.enter="enterAmount($event)"
								/>
							</div>
							<div class="row mine-integral">
								<span>需支付：</span>
								<span>{{ form.amount || 0 }}</span>
								<span style="margin-left: 4px;">元</span>
							</div>
						</div>
					</van-radio-group>
					<div class="btn-view"><van-button block round type="primary" @click="submit()">提交</van-button></div>
				</div> -->
			</van-form>
			<div class="btn-view">
				<van-button block round type="primary" @click="submit()">提交</van-button>
				<!-- <div class="row nums-text" @click="askNumsToast = true">
					<span>{{ askNumsText }}</span>
					<van-icon name="info-o" size="12" style="margin-left: 0.16rem;" />
				</div> -->
			</div>
		</div>
		<subject-select :timeOut="false" ref="subjectSelect" @confim="onSubConfim($event)"></subject-select>
		<van-popup v-model="askNumsToast" position="bottom" round>
			<div class="desc-tips" v-if="userInfo.id">
				<div class="desc-title">提问次数说明</div>
				<ol class="desc-content">
					<li>注册成功后可获得{{ userInfo.ask.free_nums }}次免费提问</li>
					<li>回答他人问题后增加{{ userInfo.ask.reply_send_nums }}次提问</li>
					<li>手机端分享朋友、群聊、朋友圈成功后增加{{ userInfo.ask.share_send_nums }}次提问</li>
				</ol>
			</div>
		</van-popup>
		<van-popup v-model="rmbToast" position="bottom" round>
			<div class="desc-tips">
				<div class="desc-title">金额支付问题，如何结算？</div>
				<div class="desc-content">
					<div>1.提问者采纳回答后，80%的提问金额会自动进入被采纳者的账号，20%的提问金额会作为平台的【答谢基金】，后续用于感谢积极帮助他人的用户，用户可以结题。</div>
					<div>
						2.问题有回答但是没有满意的答案，可以结题，结题后，90%的提问金额会返回到提问者账号，10%的提问金额会作为平台的【答谢基金】，后续用于感谢积极帮助他人的用户。
					</div>
					<div>
						3.提问7天后，问题有回答，但是没有采纳答案，也没有结题，系统会自动结题，提问金额的50%会返回给提问者账号，另50%会作为平台的【答谢基金】，后续用于感谢积极帮助他人的用户。
					</div>
					<div>4.从提问开始，如果没有人回答问题，7天内可以随时自行删除问题，若未自行删除，则系统会在问题发布7天后自动删除。提问金额会全额返回给提问者账号。</div>
				</div>
			</div>
		</van-popup>
		<!-- <van-popup v-model="shareShow" class="share-arrow-pop" @click="shareShow = false"><img class="share-arrow" :src="require('@/assets/share-arrow.png')" /></van-popup> -->
		<van-popup v-model="shareShow" position="center" round>
			<div class="share-modal">
				<img class="img" :src="require('@/assets/share-modal.png')" />
				<van-button class="btn" block round type="primary" @click="shareShow = false">我知道了</van-button>
			</div>
		</van-popup>
		<van-popup v-model="teacherShow" position="center" round>
			<div class="col cc teacher-modal">
				<img class="img" :src="$store.getters.oss(setting.customer_image)" />
				<div class="teacher">长按识别二维码添加督学老师</div>
				<div class="tips">加急获取问题答案</div>
				<van-icon name="close" color="#999999" class="close-icon" @click="teacherShow = false" />
			</div>
		</van-popup>
	</div>
</template>

<script>
import { mapState } from 'vuex';
import { Area, Uploader } from 'vant';
import subjectSelect from '@/components/subject-select/subject-select.vue';
export default {
	data() {
		return {
			fileList: [],
			// 	{
			// 		url: 'https://img01.yzcdn.cn/vant/leaf.jpg',
			// 		status: 'uploading',
			// 		message: '上传中...'
			// 	},
			// 	{
			// 		url: 'https://img01.yzcdn.cn/vant/tree.jpg',
			// 		status: 'failed',
			// 		message: '上传失败'
			// 	}
			form: {
				title: '',
				content: '',
				major_id: '',
				tag_ids: [],
				type: '',
				point: '',
				amount: '',
				image: []
			},
			displayForm: {
				major: '',
				tags: []
			},
			// 自定义标签输入框
			showTagInput: false,
			tagInputText: '',
			// 积分悬赏选项
			integralList: [100, 200, 500, 1000],
			pointInputText: '',
			// 现金悬赏选项
			rmbList: [1, 2, 5, 10],
			amountInputText: '',
			userInfo: {
				point: ''
			},
			rmbToast: false,
			askNumsToast: false,
			showPointTips: false,
			shareShow: false,
			teacherShow: false,
			setting: {}
		};
	},
	computed: {
		...mapState(['majorInfo']),
		askNumsText() {
			if (this.userInfo.ask_nums > 0) {
				return `可发布${this.userInfo.ask_nums}次提问`;
			} else {
				return `分享后可获得提问次数`;
			}
		}
	},
	components: { vanArea: Area, vanUploader: Uploader, subjectSelect },
	async created() {
		this.$bus.$on('shareSuccess', () => {
			this.getUserInfo();
		});
		this.onSubConfim(this.majorInfo);
		await this.getUserInfo();

		let { data } = await this.$service.post('auth/setShow');
		this.setting = data;
	},
	beforeDestroy() {
		this.$bus.$off('shareSuccess');
	},
	methods: {
		async getUserInfo() {
			let { data } = await this.$service.post('user_info/detail');
			this.userInfo = data;
		},
		async submit() {
			if (this.userInfo.ask_nums <= 0) {
				this.shareShow = true;
				return;
			}
			let form = JSON.parse(JSON.stringify(this.form));
			form.tag_ids = form.tag_ids.join(',');
			form.image = form.image.join(',');
			if (form.content.trim() == '') {
				this.$toast('请填写问题内容');
			}
			// else if (form.title.trim() == '') {
			// 	this.$toast('请填写问题标题');
			// }
			else if (form.major_id == '') {
				this.$toast('请选择问题分类');
			}
			// else if (form.tag_ids == '') {
			// 	this.$toast('请添加问题标签');
			// }
			// else if (form.type == '') {
			// 	this.$toast('请选择悬赏类型');
			// } else if (form.type == 1 && form.point == '') {
			// 	this.$toast('请选择积分悬赏金额');
			// } else if (form.type == 2 && form.amount == '') {
			// 	this.$toast('请选择现金悬赏金额');
			// }
			else {
				if (form.type == 1) {
					delete form.amount;
				} else if (form.type == 2) {
					delete form.point;
				}
				this.$toast.loading({
					duration: 0,
					message: '',
					forbidClick: true,
					loadingType: 'spinner'
				});
				this.$service
					.post('question/add', form)
					.then(async askRes => {
						this.getUserInfo();
						if (askRes.data && askRes.data.orderNo) {
							let patData = await this.$service.post('order/orderPay', { orderNo: askRes.data.orderNo });
							this.payH5Request(patData.data.payment)
								.then(() => {
									this.addSuccess(askRes.data.id);
								})
								.catch(() => {
									this.$toast.clear();
									this.$toast.fail({
										message: '问题未支付',
										duration: 800
									});
								});
						} else {
							this.addSuccess(askRes.data.id);
						}
					})
					.catch(() => {});
			}
		},
		addSuccess(id) {
			this.$toast.clear();
			this.$dialog
				.alert({
					title: '问题创建成功',
					message: '您的问题将于48小时内被答复',
					showConfirmButton: true,
					showCancelButton: true,
					confirmButtonText: '加急解答',
					confirmButtonColor: '#03d3bc',
					cancelButtonText: '知道了',
					cancelButtonColor: '#999999'
				})
				.then(() => {
					this.teacherShow = true;
				})
				.catch(() => {
					this.$router.replace({
						path: `/ask/detail?id=${id}`
					});
				});
			// this.$app.$emit('askAddSuccess');
		},
		enterPoint() {
			let val = Number(this.pointInputText);
			if (val < 100 || val > 1000) {
				this.$toast('自定义悬赏积分 100-1000');
			} else {
				if (this.integralList.indexOf(val) === -1) this.integralList.push(val);
				this.form.point = val;
				this.pointInputText = '';
			}
		},
		enterAmount() {
			let val = Number(this.amountInputText);
			if ((val < 1 || val > 10) && val != 0.01) {
				this.$toast('自定义悬赏金额 1-10');
			} else {
				if (this.rmbList.indexOf(val) === -1) this.rmbList.push(val);
				this.form.amount = val;
				this.amountInputText = '';
			}
		},
		// 分类选择确认
		onSubConfim(e) {
			if (!e || !e.major_id) return;
			this.form.major_id = e.major_id;
			this.getSubText();
		},
		// 获取科目的完整名称
		async getSubText() {
			let res = await this.$service.post('category/getMajor', { major_id: this.form.major_id });
			this.displayForm.major = res.data.major;
		},
		openSubjectSelect() {
			this.$refs['subjectSelect'].open();
		},
		removeTag(index) {
			this.displayForm.tags.splice(index, 1);
			this.form.tag_ids.splice(index, 1);
		},
		async enterTag() {
			if (this.displayForm.tags.length >= 5) {
				this.$toast('最多添加5个标签');
			} else if (!this.form.major_id) {
				this.$toast('请选择分类');
			} else if (this.tagInputText.trim() == '') {
				this.$toast('请输入标签名称');
			} else {
				this.$toast.loading({
					duration: 0,
					message: '',
					forbidClick: true,
					loadingType: 'spinner'
				});
				let res = await this.$service.post('category/tagAdd', { tag_names: this.tagInputText, major_id: this.form.major_id });
				this.form.tag_ids.push(res.data);
				this.displayForm.tags.push(this.tagInputText);
				this.tagInputText = '';
				this.$toast.clear();
			}
		},
		delImg(file, detail) {
			this.form.image.splice(detail.index, 1);
		},
		afterRead(file) {
			file.status = 'uploading';
			file.message = '上传中...';
			this.$service.upload(file.file).then(res => {
				this.form.image.push(res.data.url);
				file.status = 'done';
				file.content = this.$store.getters.oss(res.data.url);
				file.message = '上传成功';
			});
		},
		onFailed(errorInfo) {
			console.log('failed', errorInfo);
		}
	}
};
</script>

<style scoped lang="less">
@import '~@/styles/form.less';
@import './add.less';
</style>
